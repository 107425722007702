
import { defineComponent } from "vue";
import PopupNotification from "./components/PopupNotification.vue";
import AuthModal from "./components/modals/AuthModal.vue";
import MobileFiltersModal from "./components/modals/MobileFiltersModal.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    PopupNotification,
    AuthModal,
    MobileFiltersModal,
  },
  computed: {
    ...mapGetters(['getLanguage'])
  },
  methods: {
    getHtmlLang(lang: string) {
      return lang === "ru" ? "ru" : lang === "en" ? "en" : "uz-Cyrl";
    }
  },
  watch: {
    "$i18n.locale"(newLang) {
      document.documentElement.lang = this.getHtmlLang(newLang);
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
    this.$store.dispatch("autoLanguage");
    this.$i18n.locale = this.getLanguage;
    document.documentElement.lang = this.getHtmlLang(this.$i18n.locale);
  },
});
