import { IModalState, IRootState } from "@/interfaces/state"
import { ActionContext } from "vuex"
import { Module } from "vuex"
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper"
import router from "@/router"

type modalPayload = {
  showModal: boolean
}

const modalModule: Module<IModalState, IRootState> = {
  state(): IModalState {
    return {
      showAuthModal: false,
      showNotificationsModal: false,
      showLanguageDropdown: false,
      showNotification: false,
      notificationMessage: '',
      notificationIsClosing: false,
      showMobileFiltersModal: false,
    }
  },
  getters: {
    showAuthModal(state: IModalState) {
      return state.showAuthModal
    },
    showNotificationsModal(state: IModalState) {
      return state.showNotificationsModal
    },
    showLanguageDropdown(state: IModalState) {
      return state.showLanguageDropdown
    },
    showNotification(state: IModalState) {
      return state.showNotification
    },
    notificationMessage(state: IModalState) {
      return state.notificationMessage
    },
    notificationIsClosing(state: IModalState) {
      return state.notificationIsClosing
    },
    showMobileFiltersModal(state: IModalState) {
      return state.showMobileFiltersModal
    }
  },
  mutations: {
    setAuthModal(state: IModalState, payload: modalPayload) {
      state.showAuthModal = payload.showModal
    },
    setNotificationsModal(state: IModalState, payload: modalPayload) {
      state.showNotificationsModal = payload.showModal
    },
    setLanguageDropdown(state: IModalState, payload: modalPayload) {
      state.showLanguageDropdown = payload.showModal
    },
    setShowNotification(state: IModalState, payload: modalPayload) {
      state.showNotification = payload.showModal
    },
    setNotificationMessage(state: IModalState, payload: { message: string }) {
      state.notificationMessage = payload.message
    },
    setNotificationIsClosing(state: IModalState, payload: modalPayload) {
      state.notificationIsClosing = payload.showModal
    },
    setMobileFiltersModal(state: IModalState, payload: modalPayload) {
      state.showMobileFiltersModal = payload.showModal
    }
  },
  actions: {
    toggleAuthModal(context: ActionContext<IModalState, IModalState>) {
      const modalState = context.getters.showAuthModal
      if (modalState === false) {
        document.body.classList.add("is-popup-opened");
        trackEvent(EventType.login_popup_opened)
      } else {
        trackEvent(EventType.login_popup_close)
      }
      if (router.currentRoute.value.name === 'tenderPage') {
        context.dispatch('setIntendedRoute', router.currentRoute.value.fullPath)
      }
      context.commit('setAuthModal', { showModal: !modalState })
    },
    toggleLanguageDropdown(context: ActionContext<IModalState, IModalState>) {
      context.commit('setLanguageDropdown', { showModal: !context.getters.showLanguageDropdown })
    },
    toggleNotificationsModal(context: ActionContext<IModalState, IModalState>) {
      const modalState = context.getters.showNotificationsModal
      if (modalState === false) {
        document.body.classList.add("is-popup-opened")
        context.commit('setNotificationsModal', { showModal: true })
      } else {
        document.body.classList.add("is-popup-closed");
        setTimeout(() => {
          document.body.classList.remove("is-popup-opened");
          document.body.classList.remove("is-popup-closed");
          context.commit('setNotificationsModal', { showModal: false })
        }, 250);
      }
    },
    openNotification(context: ActionContext<IModalState, IModalState>, payload: { message: string }) {
      context.commit('setShowNotification', { showModal: true })
      context.commit('setNotificationMessage', payload)
      setTimeout(() => {
        context.dispatch('closeNotification')
      }, 10000)
    },
    closeNotification(context: ActionContext<IModalState, IModalState>) {
      context.commit('setNotificationIsClosing', { showModal: true })
      setTimeout(() => {
        context.commit('setNotificationIsClosing', { showModal: false })
        context.commit('setShowNotification', { showModal: false })
        context.commit('setNotificationMessage', { message: '' })
      }, 210)
    },
    toggleMobileFiltersModal(context: ActionContext<IModalState, IModalState>) {
      const modalState = context.getters.showMobileFiltersModal
      if (modalState === false) {
        document.body.classList.add("is-popup-opened")
        context.commit('setMobileFiltersModal', { showModal: true })
      } else {
        document.body.classList.add("is-popup-closed");
        setTimeout(() => {
          document.body.classList.remove("is-popup-opened");
          document.body.classList.remove("is-popup-closed");
          context.commit('setMobileFiltersModal', { showModal: false })
        }, 250);
      }
    }
  }
}

export default modalModule
