
import FooterMobile from "@/components/FooterMobile.vue";
import SearchBar from "@/components/SearchBar.vue";
import TenderCard from "@/components/TenderCard.vue";
import TheFooter from "@/components/TheFooter.vue";
import HeaderMain from "@/components/headers/HeaderMain.vue"; // @ is an alias to /src
import { EventType, trackEvent } from "@/helpers/AmplitudeHelper";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "HomeView",
  components: {
    HeaderMain,
    SearchBar,
    TenderCard,
    TheFooter,
    FooterMobile,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getTenders']),
    pageTitle() {
      return this.$route.name === "home_ru"
        ? "Tender.asia – бесплатный поиск закупок и тендеров по всему Узбекистану"
        : "Tender.asia – Ўзбекистон бўйлаб харидлар ва тендерларни бепул қидириш";
    },
    pageDescription() {
      return this.$route.name === "home_ru"
        ? "Бесплатно ищите закупки и получайте уведомления в Telegram. Тендерные площадки: xarid.uzex.uz, etender.uzex.uz, xt-xarid.uz, tender.mc.uz, beeline.uz"
        : "Бепул харидларни изланг ва Telegram орқали хабарлар олинг. Тендер майдончалари: xarid.uzex.uz, etender.uzex.uz, xt-xarid.uz, tender.mc.uz, beeline.uz";
    }
  },
  methods: {
    ...mapActions(['getNewTenders', 'toggleAuthModal']),
    handleHeroBtn() {
      trackEvent(EventType.main_try_free)
    }
  },
  async created() {
    const bodyElementClasses = document.body.classList;
    bodyElementClasses.remove(...bodyElementClasses);
    bodyElementClasses.add("homepage");

    if (this.$route.name === "home_ru") {
      this.$store.dispatch("selectLanguage", { language: "ru" });
    }
    
    await this.getNewTenders({})
    trackEvent(EventType.main_opened)
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        { name: "description", content: this.pageDescription }
      ],
      link: this.$route.name === "home_ru" ? [{ rel: "canonical", href: "https://tender.asia/" }] : []
    }
  }
});
