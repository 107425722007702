import { tendersClient } from "@/api/tendersClient"
import { IFilters, ISearch } from "@/interfaces/search"
import { IRootState, ITendersState } from "@/interfaces/state"
import { IPaginatedTenders, ITender } from "@/interfaces/tender"
import { ActionContext, Module } from "vuex"
import { sourceTranslator } from "@/helpers/APIHelper"
import router from "@/router"


const TENDERS_LIMIT = 20
const TENDERS_LOT_LIMIT = 50


const tendersModule: Module<ITendersState, IRootState> = {
  state(): ITendersState {
    return {
      tenders: {
        data: [] as ITender[],
        max_page: 0,
        total: 0,
      },
      tender: null,
      isLoading: false,
      hasSearchedTenders: false,
    }
  },
  getters: {
    getTenders(state: ITendersState) {
      return state.tenders
    },
    getTender(state: ITendersState) {
      return state.tender
    },
    isLoading(state: ITendersState) {
      return state.isLoading
    },
    hasSearchedTenders(state: ITendersState) {
      return state.hasSearchedTenders
    }
  },
  mutations: {
    setTenders(state: ITendersState, payload: { tenders: IPaginatedTenders }) {
      state.tenders = payload.tenders
    },
    setTender(state: ITendersState, payload: { tender: ITender }) {
      state.tender = payload.tender
    },
    setLots(state: ITendersState, payload: { tender: ITender }) {
      if (state.tender) {
        state.tender.lots = payload.tender.lots
      }
    },
    setTenderFavouriteStatus(state: ITendersState, payload: { tenderId: string, fav_id: null | number}) {
      if (state.tender && state.tender.id === payload.tenderId) {
        if (payload.fav_id === null) {
          delete state.tender.fav_id;
        } else {
          state.tender.fav_id = payload.fav_id;
        }
      }

      const foundTender = state.tenders.data.find(tender => tender.id === payload.tenderId);
      if (foundTender) {
        if (payload.fav_id === null) {
          delete foundTender.fav_id;
        } else {
          foundTender.fav_id = payload.fav_id;
        }
      }
    },
    setIsLoading(state: ITendersState, payload: { isLoading: boolean }) {
      state.isLoading = payload.isLoading
    },
    setHasSearchedTenders(state: ITendersState, value: boolean) {
      state.hasSearchedTenders = value
    }
  },
  actions: {
    async getNewTenders(
      context: ActionContext<ITendersState, ITendersState>, 
      payload: { page?: number, filters?: IFilters, page_refreshed?: boolean } = {}
    ) {
      context.commit('setHasSearchedTenders', false);
      let limit = 10
      let offset = 0
      if (payload && payload.page) {
        limit = TENDERS_LIMIT
        offset = (payload.page - 1) * limit;
      }

      const filtersPayload = {...payload.filters}
      if (payload.filters?.source !== undefined) {
        const translatedSource = sourceTranslator[payload.filters.source as keyof typeof sourceTranslator];
        if (translatedSource) {
          filtersPayload.source = translatedSource;
        }
      }

      let pageRefreshed = false
      if (payload && payload.page_refreshed) {
        pageRefreshed = true
      }

      context.commit('setIsLoading', { isLoading: true })
      const response = await tendersClient.getNewTenders(limit, offset, filtersPayload, pageRefreshed)
      const newTenders = response.tenders
      if (payload.page && payload.page > newTenders.max_page && newTenders.max_page !== 0) {
        context.commit('setPage', { page: newTenders.max_page }, { root: true })
        router.replace({
          name: router.currentRoute.value.name as string,
          query: {
            page: newTenders.max_page
          }
        })
        payload.page = newTenders.max_page
        context.dispatch('getNewTenders', payload)
      }
      if (payload.page && payload.page <= 0) {
        context.commit('setPage', { page: 1 }, { root: true })
        router.replace({
          name: router.currentRoute.value.name as string,
          query: {
            page: 1
          }
        })
        payload.page = 1
        context.dispatch('getNewTenders', payload)
      }
      context.commit('setTemplateExists', response.template_id, { root: true })
      if (Object.keys(filtersPayload).length === 0) {
        context.commit('setCanSubscribe', { canSubscribe: false }, { root: true })
      } else {
        context.commit('setCanSubscribe', { canSubscribe: true }, { root: true })
      }
      context.commit('setFilterUpdated', { updated: false }, { root: true })
      context.commit('setTenders', { tenders: newTenders })
      context.commit('setIsLoading', { isLoading: false })
      context.commit('setFilterUpdated', { updated: false }, { root: true })
      if (Object.keys(filtersPayload).length === 0) {
        context.commit('setCanSubscribe', { canSubscribe: false }, { root: true })
      } else {
        context.commit('setCanSubscribe', { canSubscribe: true }, { root: true })
      }
    },
    async getTenderDetails(
      context: ActionContext<ITendersState, ITendersState>, 
      payload: {tenderId: string, numLots: number}
    ) {
      if (payload.numLots == TENDERS_LOT_LIMIT) {
        context.commit('setIsLoading', { isLoading: true })
      }
      const tender = await tendersClient.getTender(payload.tenderId, payload.numLots)
      if (tender === null) {
        context.commit('setTender', { tender: tender })
        context.commit('setIsLoading', { isLoading: false })
        return null
      }
      if (payload.numLots > TENDERS_LOT_LIMIT) {
        context.commit('setLots', { tender: tender })
      } else {
        context.commit('setTender', { tender: tender })
      }
      context.commit('setIsLoading', { isLoading: false })
    },
    async searchTenders(
      context: ActionContext<ITendersState, ITendersState>, 
      payload: ISearch,
    ) {
      if (payload.query) {
        context.commit('setHasSearchedTenders', true);
      } else {
        context.commit('setHasSearchedTenders', false);
      }
      const offset = (payload.page - 1) * TENDERS_LIMIT

      const filtersPayload = {...payload.filters}
      if (payload.filters?.source !== undefined) {
        const translatedSource = sourceTranslator[payload.filters.source as keyof typeof sourceTranslator];
        if (translatedSource) {
          filtersPayload.source = translatedSource;
        }
      }

      let include_ngrams = false
      if (payload.include_ngrams) {
        include_ngrams = payload.include_ngrams
      }

      context.commit('setIsLoading', { isLoading: true })
      const response = await tendersClient.searchTender(payload.query, TENDERS_LIMIT, offset, include_ngrams, filtersPayload, payload.page_refreshed)
      const tenders = response.tenders
      if (tenders.total > 0 && tenders.max_page < payload.page) {
        context.commit('setPage', { page: tenders.max_page }, { root: true })
        router.replace({
          name: router.currentRoute.value.name as string,
          query: {
            ...router.currentRoute.value.query,
            page: tenders.max_page,
          }
        })
        payload.page = tenders.max_page
        context.dispatch('searchTenders', payload)
      }
      if (payload.page <= 0) {
        context.commit('setPage', { page: 1 }, { root: true })
        router.replace({
          name: router.currentRoute.value.name as string,
          query: {
            ...router.currentRoute.value.query,
            page: 1,
          }
        })
        payload.page = 1
        context.dispatch('searchTenders', payload)
      }
      context.commit('setTemplateExists', response.template_id, { root: true })
      context.commit('setCanSubscribe', { canSubscribe: true }, { root: true })
      context.commit('setFilterUpdated', { updated: false }, { root: true })
      context.commit('setTenders', { tenders: tenders })
      context.commit('setIsLoading', { isLoading: false })
      context.commit('setFilterUpdated', { updated: false }, { root: true })
      context.commit('setCanSubscribe', { canSubscribe: true }, { root: true })
    },
    setIsLoading(context: ActionContext<ITendersState, ITendersState>, payload: { isLoading: boolean }) {
      context.commit('setIsLoading', payload)
    }
  }
}

export default tendersModule
